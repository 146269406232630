import React from "react";
import { useRecoilState } from "recoil";
import { User } from "../../../../generated/urql";
import {
  enableCapitalCallDocuments,
  enablePortfolioAudit,
  enableNavV2,
} from "../../../../state/developer";
import { Box } from "../../../core/v2/Box/Box";
import { Checkbox } from "../../../core/v2/Checkbox/Checkbox";

type Props = {
  user: Pick<User, "featureFlags">;
};

export const DeveloperConsole: React.FC<Props> = () => {
  const [isPortfolioV2AuditEnabled, setPortfolioV2AuditEnabled] =
    useRecoilState(enablePortfolioAudit);

  const [isCapitalCallDocumentsEnabled, setCapitalCallDocumentsEnabled] =
    useRecoilState(enableCapitalCallDocuments);

  const [isNavV2Enabled, setNavV2Enabled] = useRecoilState(enableNavV2);

  return (
    <Box>
      <Box>
        <Checkbox
          checked={isPortfolioV2AuditEnabled}
          label="Portfolio Audit"
          onChange={() => setPortfolioV2AuditEnabled((value) => !value)}
        />
      </Box>

      <Box mt={1}>
        <Checkbox
          checked={isCapitalCallDocumentsEnabled}
          label="Capital Call Documents"
          onChange={() => setCapitalCallDocumentsEnabled((value) => !value)}
        />
      </Box>

      <Box mt={1}>
        <Checkbox
          checked={isNavV2Enabled}
          label="NAV V2"
          onChange={() => setNavV2Enabled((value) => !value)}
        />
      </Box>
    </Box>
  );
};
