import { z } from "zod";
import {
  AllocationsStatus,
  AuthenticatorCreatedFromClient,
  AuthenticatorCreatedPlatform,
  BankAccountStatus,
  BusinessInvestorRegistrationFormInput,
  DashboardRoute,
  DocumentType,
  DownloadableDocumentStatus,
  FeatureFlag,
  InvestorAccountFormInput,
  InvestorBankAccountFormInput,
  InvestorCreateOrUpdateAllocationFormInput,
  InvestorRegistrationFormInput,
  InvestorType,
  PrivateInvestorRegistrationFormInput,
  SystemStatus,
  UserAccountFormInput,
  UserInvestorRegistrationFormInput,
  UserInvestorRole,
  UserPasswordFormInput,
} from "./urql";

type Properties<T> = Required<{
  [K in keyof T]: z.ZodType<T[K], any, T[K]>;
}>;

type definedNonNullAny = {};

export const isDefinedNonNullAny = (v: any): v is definedNonNullAny =>
  v !== undefined && v !== null;

export const definedNonNullAnySchema = z
  .any()
  .refine((v) => isDefinedNonNullAny(v));

export const AllocationsStatusSchema = z.nativeEnum(AllocationsStatus);

export const AuthenticatorCreatedFromClientSchema = z.nativeEnum(
  AuthenticatorCreatedFromClient,
);

export const AuthenticatorCreatedPlatformSchema = z.nativeEnum(
  AuthenticatorCreatedPlatform,
);

export const BankAccountStatusSchema = z.nativeEnum(BankAccountStatus);

export const DashboardRouteSchema = z.nativeEnum(DashboardRoute);

export const DocumentTypeSchema = z.nativeEnum(DocumentType);

export const DownloadableDocumentStatusSchema = z.nativeEnum(
  DownloadableDocumentStatus,
);

export const FeatureFlagSchema = z.nativeEnum(FeatureFlag);

export const InvestorTypeSchema = z.nativeEnum(InvestorType);

export const SystemStatusSchema = z.nativeEnum(SystemStatus);

export const UserInvestorRoleSchema = z.nativeEnum(UserInvestorRole);

export function BusinessInvestorRegistrationFormInputSchema(): z.ZodObject<
  Properties<BusinessInvestorRegistrationFormInput>
> {
  return z.object({
    businessName: z.string().min(2, "TOO_SHORT"),
    countryOfIncorporation: z.string().min(2, "TOO_SHORT"),
  });
}

export function InvestorAccountFormInputSchema(): z.ZodObject<
  Properties<InvestorAccountFormInput>
> {
  return z.object({
    address: z.string().min(2, "TOO_SHORT").nullish(),
    businessName: z.string().min(2, "TOO_SHORT"),
    city: z.string().min(2, "TOO_SHORT").nullish(),
    country: z.string().min(2, "TOO_SHORT").nullish(),
    countryOfResidence: z.string().min(2, "TOO_SHORT").nullish(),
    nationality: z.string().min(2, "TOO_SHORT").nullish(),
    phone: z.string().min(2, "TOO_SHORT").nullish(),
    vat: z.string().min(2, "TOO_SHORT").nullish(),
  });
}

export function InvestorBankAccountFormInputSchema(): z.ZodObject<
  Properties<InvestorBankAccountFormInput>
> {
  return z.object({
    accountHolder: z.string(),
    accountNumber: z.string().max(30, "TOO_LONG").nullish(),
    bankAddress: z.string(),
    bankName: z.string(),
    currency: z.string().max(3, "TOO_LONG"),
    iban: z.string().max(34, "TOO_LONG").nullish(),
    investorId: z.string(),
    swift: z.string().max(11, "TOO_LONG").nullish(),
  });
}

export function InvestorCreateOrUpdateAllocationFormInputSchema(): z.ZodObject<
  Properties<InvestorCreateOrUpdateAllocationFormInput>
> {
  return z.object({
    amountUsd: z.number(),
    assetId: z.string(),
    investorId: z.string(),
    pendingAllocationId: z.string().nullish(),
  });
}

export function InvestorRegistrationFormInputSchema(): z.ZodObject<
  Properties<InvestorRegistrationFormInput>
> {
  return z.object({
    business: z.lazy(() =>
      BusinessInvestorRegistrationFormInputSchema().nullish(),
    ),
    private: z.lazy(() =>
      PrivateInvestorRegistrationFormInputSchema().nullish(),
    ),
    user: z.lazy(() => UserInvestorRegistrationFormInputSchema()),
  });
}

export function PrivateInvestorRegistrationFormInputSchema(): z.ZodObject<
  Properties<PrivateInvestorRegistrationFormInput>
> {
  return z.object({
    countryOfResidence: z.string().min(2, "TOO_SHORT"),
    role: z.string(),
  });
}

export function UserAccountFormInputSchema(): z.ZodObject<
  Properties<UserAccountFormInput>
> {
  return z.object({
    firstName: z.string().min(2, "TOO_SHORT").nullish(),
    lastName: z.string().min(2, "TOO_SHORT").nullish(),
  });
}

export function UserInvestorRegistrationFormInputSchema(): z.ZodObject<
  Properties<UserInvestorRegistrationFormInput>
> {
  return z.object({
    email: z.string().email(),
    firstName: z.string().min(2, "TOO_SHORT"),
    lastName: z.string().min(2, "TOO_SHORT"),
    password: z
      .string()
      .regex(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[^\s\w]).{8,}$/,
        "INVALID",
      ),
  });
}

export function UserPasswordFormInputSchema(): z.ZodObject<
  Properties<UserPasswordFormInput>
> {
  return z.object({
    currentPassword: z.string().min(1, "TOO_SHORT"),
    newPassword: z
      .string()
      .regex(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[^\s\w]).{8,}$/,
        "INVALID",
      ),
  });
}
